@import 'src/styles/helpers';

.case {
  display: grid;
  gap: 24px;
  align-items: flex-start;

  &:not(.case_searchView):nth-child(even) {

    @include media(landscape) {
      .image {
        order: 1;
      }
    }

    @include media(desktopAll) {
      .image {
        order: 1;
      }
    }
  }

  @include media(portrait) {
    gap: 24px;
  }

  @include media(landscape) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;

    &:not(.case_searchView) {
      gap: 64px;
    }
  }

  @include media(desktopAll) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;
    gap: 64px;

    &:not(.case_searchView) {
      gap: 64px;
    }
  }

  .image {

    img {
      transition: transform .3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &_searchView {
    height: 100%;
    gap: var(--margin-sm);

    @include media(tablet) {
      gap: var(--margin-xl);
      grid-template-columns: 260px 1fr;
      align-items: center;
    }

    @include media(desktopAll) {
      gap: var(--margin-xl);
      grid-template-columns: 260px 1fr;
      align-items: center;
    }

    .image {
      min-height: 168px;
      height: 168px;
      overflow: hidden;

      img {
        transition: all 0.3s ease;
      }
    }

    .content {
      padding: 0;
      gap: var(--margin-sm);

      @include media(tablet) {
        padding: var(--margin-sm) 0;
      }

      @include media(desktopAll) {
        padding: var(--margin-sm) 0;
      }
    }

    .title,
    .description {
      color: var(--secondary-200);
      transition: all 0.3s ease;
    }

    .title {
      font-size: 20px;
      line-height: 28px;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      @include media(desktopAll) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .projectName {
      @include setProperty(color, var(--primary-900), var(--primary-500));
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 12px;
      transition: all 0.3s ease;

      @include media(tablet) {
        font-size: 16px;
      }

      @include media(desktopAll) {
        font-size: 16px;
      }
    }

    &:hover {

      .image img {
        transform: scale(1.05);
      }

      .title,
      .description {
        @include setProperty(color, var(--secondary-50), var(--secondary-500));
      }

      .projectName {
        @include setProperty(color, var(--primary-500), var(--primary-900));
      }
    }
  }
}

.image {
  display: block;
  @include imgCover;
  height: 327px;
  overflow: hidden;
  position: relative;

  @include media(portrait) {
    height: 388px;
  }

  @include media(landscape) {
    height: 100%;
  }

  @include media(desktopAll) {
    height: 100%;
  }
}

.content {
  padding-bottom: 40px;
  display: grid;
  gap: 24px;

  @include media(portrait) {
    padding-bottom: 24px;
  }

  @include media(landscape) {
    padding: 24px 0;
  }

  @include media(desktopAll) {
    padding: 24px 0;
  }
}

.projectName {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  font-size: 16px;
  line-height: 20px;
  letter-spacing: var(--ls-183);
  text-transform: uppercase;
  margin-bottom: var(--margin-xs);
  font-weight: bold;

  @include media(tablet) {
    margin-bottom: 12px;
  }

  @include media(desktopAll) {
    margin-bottom: 12px;
  }
}

.title {
  display: inline-block;
  font-family: 'KharkivTone', sans-serif;
  font-size: 24px;
  line-height: 32px;

  @include media(desktopAll) {
    font-size: 32px;
    line-height: 40px;
  }
}

.description {
  padding-top: 8px;
  font-size: 14px;
  line-height: 2;
  font-weight: 300;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @include media(desktopAll) {
    padding-top: 16px;
    font-size: 16px;
  }
}

.technologies {

  &__title {
    @include setProperty(color, var(--secondary-100), var(--secondary-600));
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-md);
  }

  .technology {
    flex-shrink: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
    }
  }
}

.viewButton {
  @include setProperty(color, var(--primary-500), var(--primary-900));
  display: inline-block;
  cursor: pointer;
  margin-left: -24px;
  font-size: 16px;
  line-height: 1;
  font-family: 'KharkivTone', sans-serif;
}
